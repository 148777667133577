import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { snakeCase } from "lodash-es";
import { BottomSheet, Button, H5, SideDrawer, Spacing } from "@boxt/design-system";

import { type Products, ProductTypeToCategory } from "@Collections/products";
import HelpInfo from "@Components/pages/common/HelpInfo";
import type { StyleConfig } from "@Components/Trans/Trans";
import { useAnalytics } from "@Hooks/useAnalytics";
import { ReactComponent as InfoSVG } from "@Images/icons/info.svg";

import { QuestionH4 } from "../styles";

import sliderImages from "./BottomSheetContent/slides";
import BottomSheetContent from "./BottomSheetContent";
import { Container, PillButton, QuestionIcon } from "./styles";

export type Props = {
  buttonText: string;
  extraInfoComponent?: "bottom-sheet" | "side-drawer";
  questionLocalePath?: string;
  i18nNamespace: string;
  isPill?: boolean;
  isTextOnly?: boolean;
  productType?: Products;
  questionTimeSignature?: string;
  styleConfig?: StyleConfig;
};

const Help = ({
  buttonText,
  extraInfoComponent = "side-drawer",
  i18nNamespace,
  isPill = false,
  isTextOnly = false,
  productType,
  questionLocalePath,
  questionTimeSignature,
  styleConfig,
}: Props) => {
  const [isExtraInfoOpen, setIsExtraInfoOpen] = useState(false);
  const { t } = useTranslation(i18nNamespace);

  const segmentAnalytics = useAnalytics();

  const bottomSheetCTAButtonText = t(`${i18nNamespace}:questions.${questionLocalePath}.extraInfoComponentCTAText`);

  const bottomSheetCTAButtonLink = t(`${i18nNamespace}:questions.${questionLocalePath}.extraInfoComponentCTALink`);

  const shouldRenderCTAButton = Boolean(bottomSheetCTAButtonLink) && Boolean(bottomSheetCTAButtonText);

  const bottomSheetImages = questionLocalePath ? sliderImages[questionLocalePath] : [];

  const handleOnCtaClick = () => {
    if (productType) {
      segmentAnalytics?.track("cta_clicked", {
        cta_name: `${ProductTypeToCategory[productType]}_screener_${snakeCase(bottomSheetCTAButtonText)}`,
        category: ProductTypeToCategory[productType],
        click_text: bottomSheetCTAButtonText,
      });
    }

    window.open(bottomSheetCTAButtonLink, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Container>
        {isPill ? (
          <PillButton onClick={() => setIsExtraInfoOpen((isOpen) => !isOpen)}>
            <QuestionIcon />
            <Spacing mh={1}>
              <H5 weight="regular" boxtTheme="slate">
                {buttonText}
              </H5>
            </Spacing>
          </PillButton>
        ) : (
          <Button
            onClick={() => setIsExtraInfoOpen((isOpen) => !isOpen)}
            skin="plain"
            icon={{
              svg: <InfoSVG />,
              afix: "left",
              size: "small",
            }}
          >
            {buttonText}
          </Button>
        )}
      </Container>
      {extraInfoComponent === "side-drawer" ? (
        <SideDrawer
          isOpen={isExtraInfoOpen}
          setIsOpen={setIsExtraInfoOpen}
          testId={`screener-${questionLocalePath}-help-info`}
          title={
            <QuestionH4 align="center" $highlightTheme="coral">
              {questionTimeSignature
                ? t(`questions.${questionLocalePath}.header.${questionTimeSignature}`)
                : t(`questions.${questionLocalePath}.header`)}
            </QuestionH4>
          }
        >
          <HelpInfo
            i18n={{
              namespace: i18nNamespace,
              question: questionLocalePath || "",
            }}
          />
        </SideDrawer>
      ) : (
        <BottomSheet
          open={isExtraInfoOpen}
          setIsOpen={setIsExtraInfoOpen}
          headerTitle={t(`${i18nNamespace}:questions.${questionLocalePath}.extraInfoComponentHeader`)}
          maxWidth={isTextOnly ? undefined : 1100}
        >
          <BottomSheetContent
            styleConfig={styleConfig}
            isTextOnly={isTextOnly}
            bottomSheetImages={bottomSheetImages}
            ctaButtonText={bottomSheetCTAButtonText}
            i18nNamespace={`${i18nNamespace}:questions.${questionLocalePath}.extraInfoComponentText`}
            sliderAltText={t(`${i18nNamespace}:questions.${questionLocalePath}.extraInfoComponentSliderAlt`)}
            shouldRenderCTAButton={shouldRenderCTAButton}
            onCtaClick={handleOnCtaClick}
          />
        </BottomSheet>
      )}
    </>
  );
};

export default Help;
