import Link from "next/link";
import { Button } from "@boxt/design-system";

// children is a stringified JSON object with the following example structure:
// '{"href": "https://www.gov.uk/search-property-infor…try", "buttonText": "GOV.UK", "type": "external"}'
type Props = {
  children: string;
};

const ButtonLink = ({ children }: Props) => {
  const linkAttributes = JSON.parse(children);

  const href = linkAttributes.href;

  const buttonText = linkAttributes.buttonText;

  const isExternal = linkAttributes.type === "external";

  return isExternal ? (
    <Button role="link" boxtTheme="coral" skin="link" href={href} target="_blank" rel="noopener noreferrer">
      {buttonText}
    </Button>
  ) : (
    <Link href={href} target="_blank" rel="noopener noreferrer" passHref legacyBehavior>
      <Button skin="link">{buttonText}</Button>
    </Link>
  );
};

export default ButtonLink;
