import { forwardRef, type HTMLAttributes, useEffect, useMemo, useState } from "react";
import type { CSSProp, IStyledComponent, RuleSet } from "styled-components";
import type { KeenSliderInstance, KeenSliderOptions } from "keen-slider/react";
import { useKeenSlider } from "keen-slider/react";
import type { ButtonTheme } from "@boxt/design-system";
import { breakpoints } from "@boxt/design-system";

import Arrows from "@Components/pages/common/Slider/Arrows";
import type { ArrowTheme } from "@Components/pages/common/Slider/types";
import useMedia from "@Hooks/useMedia";

import { ContentWrapper, Icon, IconWrapper, StyledButton, StyledSpacing, Wrapper } from "./styles";

const getDotPerView = (
  slides: {
    abs: number;
    distance: number;
    portion: number;
    size: number;
  }[] = [],
): { index: number }[] => {
  const perView = slides.filter((slide) => Math.round(slide.portion) === 1).length;

  let i = 1;
  const result: { index: number }[] = [];
  while (i + (perView - 1) <= slides.length) {
    result.push({ index: i - 1 });
    i++;
  }
  return result;
};

export const defaultConfig: KeenSliderOptions = {
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      slides: {
        perView: 1,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
      loop: true,
      initial: 0,
    },
    [`(min-width: ${breakpoints.md.width})`]: {
      slides: {
        perView: 2,
        origin: "center",
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(1, true, { duration: 0 }),
      loop: false,
      initial: 1,
    },
    [`(min-width: ${breakpoints.lg.width})`]: {
      slides: {
        perView: 3,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
      drag: false,
      loop: false,
      initial: 0,
    },
  },
};

export type Props = {
  actionButtonText?: string;
  actionButtonTheme?: ButtonTheme;
  alwaysShowDots?: boolean;
  arrowTheme?: ArrowTheme;
  controllerStyle?: RuleSet<object>;
  dataTestId?: string;
  DotIcon?: IStyledComponent<"web", HTMLAttributes<HTMLDivElement> & { $selected: boolean }>;
  children: JSX.Element[] | JSX.Element;
  useDotPerSlide?: boolean;
  duration?: number;
  initiallyLoop?: boolean;
  includeSpacing?: boolean;
  isOverflow?: boolean;
  onActionButtonClick?: () => void;
  onSlideChange?: (index: number) => void;
  sliderOptions?: KeenSliderOptions;
  showArrows?: boolean;
  showLeftArrow?: boolean;
  showDotsController?: boolean;
  spacingStyle?: CSSProp;
  wrapperStyle?: CSSProp;
};

const Slider = (
  {
    actionButtonText,
    actionButtonTheme = "jade",
    alwaysShowDots = false,
    arrowTheme = { size: "large", color: "dark", position: "inside" },
    children,
    controllerStyle = [],
    dataTestId,
    DotIcon = Icon,
    useDotPerSlide = false,
    duration = 4000,
    initiallyLoop = true,
    includeSpacing = true,
    isOverflow,
    sliderOptions = defaultConfig,
    showArrows = false,
    showDotsController = true,
    onActionButtonClick,
    onSlideChange,
    showLeftArrow = false,
    spacingStyle = [],
    wrapperStyle = [],
  }: Props,
  ref,
) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const [loop, setLoop] = useState(initiallyLoop);
  const [isLoaded, setIsLoaded] = useState(false);
  const [current, setCurrent] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    created(s) {
      setIsLoaded(true);
      setCurrent(s.track.details?.rel || 0);
    },
    slideChanged(s) {
      setCurrent(s.track.details?.rel || 0);
      onSlideChange?.(s.track.details?.rel || 0);
    },
    dragStarted() {
      if (duration) {
        setLoop(false);
      }
    },
    dragEnded() {
      if (duration) {
        setLoop(true);
      }
    },
    ...sliderOptions,
  });

  useEffect(() => {
    // set slider to ref, to make it available for parent
    if (slider && ref) {
      ref.current = slider.current;
    }
  }, [slider, ref]);

  useEffect(() => {
    let interval;
    if (duration) {
      interval = setInterval(() => {
        if (slider.current && loop && (isMobile || slider.current.options?.loop)) {
          slider.current.next();
        }
      }, duration);
    }
    return () => interval && clearInterval(interval);
  }, [loop, slider, isMobile, duration]);

  const iconArray = useMemo(() => {
    if (isLoaded && slider?.current?.options) {
      if (useDotPerSlide) {
        return slider?.current?.track?.details?.slides.map((_, index) => ({ index }));
      }

      return getDotPerView(slider?.current?.track?.details?.slides);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, isMobile, isLg, isLoaded, useDotPerSlide]);

  return (
    <StyledSpacing css={spacingStyle} mh={includeSpacing ? { sm: 2, md: 0, lg: 4 } : 0} data-testid={dataTestId}>
      <ContentWrapper $isOverflow={isOverflow}>
        <Wrapper $cssObject={wrapperStyle} ref={sliderRef} className="keen-slider">
          {children}
        </Wrapper>
        {showArrows && Number(iconArray?.length) > 1 && (
          <Arrows
            showLeftArrow={showLeftArrow}
            arrowTheme={arrowTheme}
            activeSlide={current}
            sliderInstance={slider.current}
          />
        )}
      </ContentWrapper>

      {showDotsController && Number(iconArray?.length) > 1 && (
        <IconWrapper $alwaysShowDots={alwaysShowDots} data-testid="icon-wrapper" $cssStyle={controllerStyle}>
          {iconArray?.map(({ index }) => (
            <DotIcon
              $selected={current === index}
              key={`dot-${index}`}
              onClick={() => slider.current?.moveToIdx(index, false)}
            />
          ))}
        </IconWrapper>
      )}
      {actionButtonText && (
        <StyledButton
          boxtTheme={actionButtonTheme}
          onClick={() => {
            onActionButtonClick?.();
            slider.current?.moveToIdx(current + 1, false);
          }}
          data-testid={`slider-action-button-${current}`}
        >
          {actionButtonText}
        </StyledButton>
      )}
    </StyledSpacing>
  );
};

export default forwardRef<KeenSliderInstance, Props>(Slider);
