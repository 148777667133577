import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, rem } from "@boxt/design-system";

import type { ArrowTheme } from "@Components/pages/common/Slider/types";

type Direction = "left" | "right";

const sizeArrowTheme = {
  large: css`
    width: ${rem(52)};
    height: ${rem(52)};
    top: calc(50% - ${rem(26)});
    svg {
      width: ${rem(16)};
      height: ${rem(16)};
    }
  `,
  small: css`
    width: ${rem(32)};
    height: ${rem(32)};
    top: calc(50% - ${rem(16)});
    svg {
      width: ${rem(12)};
      height: ${rem(12)};
    }
  `,
};

const colorArrowTheme = {
  dark: css`
    background: ${colors.slate.darker};
    color: ${colors.white};
  `,
  light: css`
    color: ${colors.slate.normal};
    border: 1px solid ${colors.slate.normal};
    &:hover {
      background-color: ${colors.slate.normal};
      color: ${colors.white};
    }
  `,
  lightInverted: css`
    border: 1px solid ${colors.white};
    color: ${colors.white};
  `,
  lighter: css`
    background: ${tinyColor(colors.slate.normal).setAlpha(0.8).toRgbString()};
    color: ${colors.white};
  `,
};

const positionArrowTheme = {
  inside: css<{ $direction: Direction }>`
    left: ${({ $direction }) => ($direction === "left" ? rem(16) : "unset")};
    right: ${({ $direction }) => ($direction === "right" ? rem(16) : "unset")};
  `,
  outside: css<{ $direction: Direction }>`
    left: ${({ $direction }) => ($direction === "left" ? rem(-64) : "unset")};
    right: ${({ $direction }) => ($direction === "right" ? rem(-64) : "unset")};
  `,
};

export const Arrow = styled.div<{ $hidden: boolean; $direction: Direction; $arrowTheme: ArrowTheme }>`
  align-items: center;
  padding-left: ${({ $direction }) => $direction === "right" && `${rem(2)}`};
  padding-right: ${({ $direction }) => $direction === "left" && `${rem(2)}`};
  border-radius: 50%;
  cursor: pointer;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
  justify-content: center;
  position: absolute;
  ${({ $arrowTheme }) => sizeArrowTheme[$arrowTheme.size]}
  ${({ $arrowTheme }) => colorArrowTheme[$arrowTheme.color]}
  ${({ $arrowTheme }) => positionArrowTheme[$arrowTheme.position]}
  svg {
    transform: ${({ $direction }) => ($direction === "right" ? "initial" : "rotate(180deg)")};
  }
`;

export const ArrowsContainer = styled.div<{ $hideOnMobile?: boolean }>`
  display: ${({ $hideOnMobile }) => ($hideOnMobile ? "none" : "block")};
  ${breakpoint("lg")} {
    display: block;
  }
`;
