import useTranslation from "next-translate/useTranslation";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import type { MapInstruction } from "@Components/Map/types";

import { Container, Content, ImageContainer, StyledButton, Wrapper } from "./styles";

export type Props = {
  onConfirm: () => void;
};

const DesktopView = ({ onConfirm }) => {
  const { t } = useTranslation("map");
  const { t: tCommon } = useTranslation("common");

  const mapInstructions: MapInstruction[] = t("select-roof.cards", {}, { returnObjects: true });

  return (
    <Wrapper>
      <Container>
        <Spacing mt={{ md: 2, lg: 3 }} mb={{ md: 3, lg: 4 }} mh={{ md: 2, lg: 3 }}>
          <Content>
            {mapInstructions.map(({ desktopImage, title, subtitle }, index) => {
              return (
                <div key={title} data-testid="select-your-roof-desktop-card">
                  <Spacing mb={2}>
                    <ImageContainer data-testid="select-your-roof-desktop-image">
                      <Image src={desktopImage} alt={title} layout="fill" objectFit="cover" />
                    </ImageContainer>
                  </Spacing>
                  <Spacing mb={1}>
                    <H5>{title}</H5>
                  </Spacing>
                  <Spacing mb={3}>
                    <Paragraph data-testid={`select-your-roof-desktop-card-description-${index}`}>{subtitle}</Paragraph>
                  </Spacing>
                </div>
              );
            })}
          </Content>
          <StyledButton boxtTheme="jade" onClick={onConfirm}>
            {tCommon("actions.get-started")}
          </StyledButton>
        </Spacing>
      </Container>
    </Wrapper>
  );
};

export default DesktopView;
