const sliderImages: Record<string, string[]> = {
  ageOfProperty: [
    "https://boxt-bcms.imgix.net/TYtJHJwLRDajJt3pj4b9",
    "https://boxt-bcms.imgix.net/GHit6SKTMy4iCHABynkX",
    "https://boxt-bcms.imgix.net/HMGFeBiJRoOLo5NSGblX",
    "https://boxt-bcms.imgix.net/hoP8XUTROuqU05b2j6G5",
  ],
  batterySpace: [
    "https://boxt-bcms.imgix.net/mzaKIpCTJqx7dnFA77aP",
    "https://boxt-bcms.imgix.net/wk1elfQAQNqHBwmJtFSg",
    "https://boxt-bcms.imgix.net/7bc4i9FBTcubwaGyuqhb",
    "https://boxt-bcms.imgix.net/e8cHnVOSuWQMPUZ1WKkP",
  ],
  "epc.freetext": [
    "https://boxt-bcms.imgix.net/7FXhnGM8RLmfdeojyms1",
    "https://boxt-bcms.imgix.net/j1iPONu3Q4OmL1DZIrF5",
    "https://boxt-bcms.imgix.net/6U547fyKQFWRagIlo7dF",
  ],
  "annualElectricityUsage.freetext": [
    "https://boxt-bcms.imgix.net/scWVZyqQ3yRLeyXd1xXB",
    "https://boxt-bcms.imgix.net/rgXhVUuWR6OOYne1zoUQ",
  ],
  "floorArea.freetext": ["https://boxt-bcms.imgix.net/X9uzQZGLTCefiNm3MG8T"],
  "heatingUsage.freetext": ["https://boxt-bcms.imgix.net/MfBl6GnSW2twkvQdZ9eQ"],
  "hotWaterUsage.freetext": ["https://boxt-bcms.imgix.net/MfBl6GnSW2twkvQdZ9eQ"],
  "numOfRadiators.freetext": [
    "https://boxt-bcms.imgix.net/nFFq8sqTTsL3QMEQ6sTJ",
    "https://boxt-bcms.imgix.net/Lcvgp5nXR6iIk05HVTMQ",
    "https://boxt-bcms.imgix.net/sOscjbgJQ9ezYcAbvhKb",
    "https://boxt-bcms.imgix.net/kATMzIRZ2k30P2Iataig",
    "https://boxt-bcms.imgix.net/gAJFVCrbRWM45RHaZ2Ju",
  ],
  outdoorUnitSpace: [
    "https://boxt-bcms.imgix.net/aHKVQpqCSjikWGc3DcnB",
    "https://boxt-bcms.imgix.net/o7msSJd0Q2yzh1o4fUdZ",
  ],
  wallOrLoftInsulation: ["https://boxt-bcms.imgix.net/fpSuwWwIQjSZUQ0BPGbf"],
  roomForCylinder: ["https://boxt-bcms.imgix.net/UCvlnafS9SyMWC2TSRYI"],
  powerCuts: ["https://boxt-bcms.imgix.net/OwM6ezFTWyfY0gc36bSU"],
  "costOfUnitOfElectricity.unit_of_electricity": [
    "https://boxt-bcms.imgix.net/rz5BPB1VR6GD0NuVkP9D",
    "https://boxt-bcms.imgix.net/scWVZyqQ3yRLeyXd1xXB",
  ],
  ownSolarSystem: ["https://boxt-bcms.imgix.net/23sBDpkTK26QQu1GppmV"],
};

export default sliderImages;
