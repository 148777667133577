import type { ForwardedRef } from "react";
import { forwardRef, useState } from "react";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import type { FontTheme } from "@boxt/design-system";

import type { Products } from "@Collections/products";
import type { Question } from "@StateMachines/Screener";
import { LoadingIcon } from "@Components/pages/photos";
import type { Placeholder } from "@Components/screener/Postcode/components/Input/Input";
import { landing } from "@Locators";

import { Icon, Input, Tip } from "./components";
import { Button, Container } from "./styles";
import validationSchema from "./validationSchema";

export type Props = {
  onSubmit: ({ postcode, productType }: { postcode: string; productType?: Products }) => Promise<any>;
  placeholder: Placeholder;
  submitText: string | undefined;
  i18nNamespace?: string;
  onFocus?: () => void;
  hintKey?: string;
  label: string | undefined;
  tooltipBgColor?: string;
  tooltipTextTheme?: FontTheme;
  buttonColor?: string | null;
  productType: Products | undefined;
  initialPostcode?: string;
};

type Values = {
  [Question.Postcode]: string;
};

const Postcode = forwardRef(
  (
    {
      onSubmit,
      placeholder,
      label,
      submitText,
      onFocus,
      hintKey,
      i18nNamespace,
      buttonColor,
      tooltipTextTheme,
      tooltipBgColor,
      productType,
      initialPostcode,
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOnSubmit = async (values: Values, { setErrors }: FormikHelpers<Values>) => {
      const formatPostcode = values.postcode.trim().toUpperCase();
      try {
        setIsSubmitting(true);
        await onSubmit({ postcode: formatPostcode, productType });
      } catch {
        setIsSubmitting(false);
        setErrors({ postcode: "inactive" });
      }
    };

    return (
      <Formik
        initialValues={{ postcode: initialPostcode ?? "" }}
        onSubmit={handleOnSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
        validateOnChange
      >
        <Container>
          <Icon />
          <Input placeholder={placeholder} onFocus={onFocus} aria-label={label} ref={ref} />
          <Button
            data-testid={landing.postcode.button}
            type="submit"
            disabled={isSubmitting}
            $backgroundColor={buttonColor}
            $productType={productType}
          >
            {isSubmitting ? <LoadingIcon data-testid="loading-icon" /> : <span>{submitText}</span>}
          </Button>
          <Tip
            i18nNamespace={i18nNamespace}
            tooltipBgColor={tooltipBgColor}
            tooltipTextTheme={tooltipTextTheme}
            infoKey={hintKey}
          />
        </Container>
      </Formik>
    );
  },
);

export default Postcode;
