import type { FontTheme } from "@boxt/design-system";
import { Paragraph } from "@boxt/design-system";

import type { ArrowPositionType } from "./styles";
import { Container } from "./styles";

export type TooltipPosition = "top" | "bottom";

export type Props = {
  text: string;
  bgColor?: string;
  textTheme?: FontTheme;
  position?: TooltipPosition;
  weight?: "regular" | "bold";
  arrowPosition?: ArrowPositionType;
};

const Tooltip = ({
  text,
  textTheme,
  bgColor,
  position = "bottom",
  weight = "regular",
  arrowPosition = "center",
}: Props) => (
  <Container mt={2} mh="auto" $bgColor={bgColor} $position={position} $arrowPosition={arrowPosition}>
    <Paragraph boxtTheme={textTheme || "dark"} align="center" top={4} bottom={4} weight={weight}>
      {text}
    </Paragraph>
  </Container>
);

export default Tooltip;
