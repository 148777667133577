import type { FontTheme } from "@boxt/design-system";
import { colors } from "@boxt/design-system";

// mapper between font themes (used in Butter CMS) and typography colors
export const FONT_COLOR: Record<FontTheme, string> = {
  acidGreen: colors.acidGreen.normal,
  acidGreenLight: colors.acidGreen.light,
  acidGreenLighter: colors.acidGreen.lighter,
  acidGreenDark: colors.acidGreen.dark,
  acidGreenDarker: colors.acidGreen.darker,
  coal: colors.coal.normal,
  coalLight: colors.coal.light,
  coalLighter: colors.coal.lighter,
  coalDark: colors.coal.dark,
  coalDarker: colors.coal.darker,
  coral: colors.coral.normal,
  coralLighter: colors.coral.lighter,
  coralLight: colors.coral.light,
  coralDark: colors.coral.dark,
  coralDarker: colors.coral.darker,
  dark: colors.white,
  error: colors.error,
  issue: colors.issue,
  jade: colors.jade.normal,
  jadeLight: colors.jade.light,
  jadeDark: colors.jade.dark,
  jadeDarker: colors.jade.darker,
  platinum: colors.platinum.normal,
  platinumLight: colors.platinum.light,
  platinumDark: colors.platinum.dark,
  platinumDarker: colors.platinum.darker,
  slate: colors.slate.normal,
  slateLight: colors.slate.light,
  slateLighter: colors.slate.lighter,
  slateLightest: colors.slate.lightest,
  slateDark: colors.slate.dark,
  slateDarker: colors.slate.darker,
  success: colors.success,
  saffron: colors.saffron.normal,
  saffronLight: colors.saffron.light,
  saffronDark: colors.saffron.dark,
  saffronDarker: colors.saffron.darker,
  sky: colors.sky.normal,
  skyLight: colors.sky.light,
  skyDark: colors.sky.dark,
  skyDarker: colors.sky.darker,
  jadeLighter: colors.jade.lighter,
  platinumLighter: colors.platinum.lighter,
  saffronLighter: colors.saffron.lighter,
  skyLighter: colors.sky.lighter,
};

export const BACKGROUND_COLORS: Record<FontTheme | "white", string> = {
  white: colors.white,
  ...FONT_COLOR,
};
