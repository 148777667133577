"use client";

import styled from "styled-components";

export const FreeTextWrapper = styled.div`
  div {
    padding: 0;
    box-shadow: none;
    input {
      margin: 0;
    }
  }
`;
