import Image from "@Components/Image";

import { Container, Cover } from "./styles";

type Props = {
  altText: string;
  backgroundImage: string;
};

const SliderItem = ({ altText, backgroundImage }: Props) => {
  return (
    <Container>
      <Cover data-testid="slider-item-cover">
        <Image src={backgroundImage} alt={altText} layout="fill" objectFit="contain" />
      </Cover>
    </Container>
  );
};

export default SliderItem;
