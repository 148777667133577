import useTranslation from "next-translate/useTranslation";
import type { FormikErrors } from "formik";
import { FreeText as Text, ScreeningTextArea } from "@boxt/design-system";

import { Question } from "@StateMachines/Screener";
import { screener } from "@Locators";

import { FreeTextWrapper } from "./styles";
import { getValidationSchema } from "./validationSchema";

export type Props = {
  counterConfig: {
    min: number;
    max: number;
  };
  getValidationMessage?: (_errors: FormikErrors<{ [key: string]: string }>) => string;
  hasQuestionBackground?: boolean;
  i18nNamespace: string;
  initialValue: string;
  isAnswering: boolean;
  isBorderRadiusApplied?: boolean;
  isInvalidHandled?: boolean;
  min?: number;
  onAnswer: (question: Question, answer: string | null) => void;
  onSkip?: () => void;
  question: Question;
  questionParentKey: Question;
  size?: "medium" | "large";
  step?: number;
};

const textAreaQuestions = [
  Question.BrokenPlace,
  Question.BoilerGeneralProblemDescription,
  Question.BoilerGasSmellDescription,
  Question.BoilerCoAlarmDescription,
  Question.EvGeneralProblemDescription,
  Question.AcGeneralProblemDescription,
];

const numberInputQuestions = [
  Question.AnnualElectricityUsage,
  Question.FloorArea,
  Question.HeatingUsage,
  Question.HotWaterUsage,
  Question.NumOfRadiators,
  Question.NumOfPanels,
];

const FreeText = ({
  counterConfig,
  getValidationMessage,
  hasQuestionBackground = true,
  i18nNamespace,
  initialValue,
  isAnswering,
  isBorderRadiusApplied = false,
  isInvalidHandled,
  min,
  onAnswer,
  onSkip,
  question,
  questionParentKey,
  step,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");
  const asTextarea = textAreaQuestions.includes(questionParentKey);
  const skipKey = `questions.${questionParentKey}.freetext.skipText`;
  const skipText = t(skipKey, null, { fallback: "" });
  const hasSkipText = `${i18nNamespace}:${skipKey}` !== skipText;

  const handleOnSkip = () => {
    if (onSkip) {
      onSkip();
    } else {
      onAnswer(question, null);
    }
  };

  return (
    <FreeTextWrapper>
      {asTextarea ? (
        <ScreeningTextArea
          testId={`${questionParentKey}-${screener.freeText}`}
          getValidationMessage={getValidationMessage}
          buttonText={t(`questions.${questionParentKey}.freetext.btnText`)}
          labelText={t(`questions.${questionParentKey}.freetext.label`)}
          id={questionParentKey}
          name={questionParentKey}
          initialValue={initialValue}
          onSkip={handleOnSkip}
          onSubmit={(values) => onAnswer(question, values[questionParentKey])}
          placeholder={t(`questions.${questionParentKey}.freetext.placeholder`)}
          {...(hasSkipText && { skipText })}
          validationSchema={getValidationSchema(questionParentKey, tCommon, counterConfig)}
          isBorderRadiusApplied={isBorderRadiusApplied}
          isInvalidHandled={isInvalidHandled}
          hasQuestionBackground={hasQuestionBackground}
          isAnswering={isAnswering}
        />
      ) : (
        <Text
          testId={`${questionParentKey}-${screener.freeText}`}
          getValidationMessage={getValidationMessage}
          buttonText={t(`questions.${questionParentKey}.freetext.btnText`)}
          labelText={t(`questions.${questionParentKey}.freetext.label`)}
          id={questionParentKey}
          name={questionParentKey}
          initialValue={initialValue}
          onSkip={handleOnSkip}
          onSubmit={(values) => {
            onAnswer(question, values[questionParentKey]);
          }}
          placeholder={t(`questions.${questionParentKey}.freetext.placeholder`)}
          {...(hasSkipText && { skipText })}
          validationSchema={getValidationSchema(questionParentKey, tCommon, counterConfig)}
          isBorderRadiusApplied={isBorderRadiusApplied}
          isInvalidHandled={isInvalidHandled}
          min={min}
          step={step}
          type={numberInputQuestions.includes(questionParentKey as Question) ? "number" : "text"}
          hasQuestionBackground={hasQuestionBackground}
          isAnswering={isAnswering}
        />
      )}
    </FreeTextWrapper>
  );
};

export default FreeText;
