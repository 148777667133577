import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useField } from "formik";
import { kebabCase } from "lodash-es";
import { Paragraph, Radio } from "@boxt/design-system";

import type { Theme } from "@Collections/themeType";

export type Props = {
  as?: React.ElementType;
  name: string;
  id: string;
  i18nNamespace: string;
  isChecked?: boolean;
  testId?: string;
  theme?: Theme;
  value: string;
};

const FormikRadio = ({ as, id, name, value, i18nNamespace, testId, theme = "coral" }: Props) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { t } = useTranslation(i18nNamespace);

  const tPrefix = `form.${kebabCase(name)}.${kebabCase(value)}`;

  const isChecked = field.value === value;

  return (
    <Radio
      as={as}
      isChecked={isChecked}
      testId={testId}
      id={id}
      name={name}
      boxtTheme={isChecked ? theme : "slate"}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    >
      <Paragraph boxtTheme="slate" as="span">
        {t(`${tPrefix}.label`)}
      </Paragraph>
    </Radio>
  );
};

export default FormikRadio;
