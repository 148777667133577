import type { InputHTMLAttributes, PropsWithChildren } from "react";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useField } from "formik";
import { kebabCase } from "lodash-es";
import { Rifm } from "rifm";
import { Spacing, TextInput } from "@boxt/design-system";

export type Props = PropsWithChildren & {
  hasHelperText?: boolean;
  hasPlaceholder?: boolean;
  i18nNamespace: string;
  name: string;
  testId?: string;
  light?: boolean;
  format?: (value: string) => string;
  append?: (value: string) => string;
  inputMask?: (value: string) => boolean;
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const FormikTextInput = ({
  children,
  disabled = false,
  hasPlaceholder = false,
  hasHelperText = false,
  i18nNamespace,
  light = false,
  name,
  onChange,
  testId,
  type = "text",
  max,
  min,
  format,
  append,
  inputMask,
  label,
  className,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const { error, touched, value } = meta;

  const { t } = useTranslation(i18nNamespace);

  const tPrefix = `form.${kebabCase(label || name)}`;
  const isInvalid = error && touched;

  return (
    <Spacing mb={3}>
      {format ? (
        <Rifm
          format={format}
          onChange={(value) => setValue(value)}
          value={value}
          append={append}
          mask={inputMask?.(value)}
        >
          {({ value, onChange }) => (
            <TextInput
              className={className}
              testId={testId}
              data-testid={testId}
              disabled={disabled}
              helperText={hasHelperText && t(`${tPrefix}.help`)}
              id={name}
              invalid={Boolean(isInvalid)}
              invalidText={Boolean(isInvalid) && t(`form.errors.${error}`)}
              labelText={
                <Trans
                  i18nKey={`${i18nNamespace}:${tPrefix}.label`}
                  components={{
                    strong: <strong />,
                  }}
                />
              }
              light={light}
              name={name}
              onBlur={field.onBlur}
              onChange={onChange}
              placeholder={hasPlaceholder ? t(`${tPrefix}.placeholder`) : ""}
              value={value}
              type={type}
              max={max}
              min={min}
            />
          )}
        </Rifm>
      ) : (
        <TextInput
          className={className}
          testId={testId}
          data-testid={testId}
          disabled={disabled}
          helperText={hasHelperText && t(`${tPrefix}.help`)}
          id={name}
          invalid={Boolean(isInvalid)}
          invalidText={Boolean(isInvalid) && t(`form.errors.${error}`)}
          labelText={
            <Trans
              i18nKey={`${i18nNamespace}:${tPrefix}.label`}
              components={{
                strong: <strong />,
              }}
            />
          }
          light={light}
          placeholder={hasPlaceholder ? t(`${tPrefix}.placeholder`) : ""}
          type={type}
          max={max}
          min={min}
          {...field}
          name={name}
          value={value}
          onChange={onChange || field.onChange}
        />
      )}
      {children}
    </Spacing>
  );
};

export default FormikTextInput;
