import { useCallback, useMemo, useState } from "react";
import Fuse from "fuse.js";

import type { Event } from "@StateMachines/Screener";

export type FilterableItem = {
  meta: {
    hasInfo?: boolean;
    event?: Event;
    next?: string;
    isOutsideList?: boolean;
    hasNoIcon?: boolean;
  };
  value: string;
};

const useFilterableItems = (items: FilterableItem[], defaultVisibleItemCount = 5) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterText, setFilterText] = useState("");
  const outsideListItems = useMemo(() => items?.filter((item) => item.meta?.isOutsideList), [items]);
  const filterableListItems = useMemo(() => items?.filter((item) => !item.meta?.isOutsideList), [items]);

  const expandList = useCallback(() => {
    setIsExpanded(true);
  }, [setIsExpanded]);

  const clearFilterText = useCallback(() => {
    setFilterText("");
  }, []);

  const filteredItems = useMemo(() => {
    let newFilteredItems = filterableListItems;

    if (filterText) {
      newFilteredItems = new Fuse(filterableListItems, {
        keys: ["value"],
      })
        .search(filterText)
        .reduce((acc, { item }) => [...acc, item], []);
    }

    return isExpanded ? newFilteredItems : newFilteredItems?.slice(0, defaultVisibleItemCount);
  }, [filterableListItems, filterText, isExpanded, defaultVisibleItemCount]);

  return {
    filteredItems,
    outsideListItems,
    setFilterText,
    filterText,
    clearFilterText,
    isExpanded,
    expandList,
  };
};

export default useFilterableItems;
