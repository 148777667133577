"use client";

import styled, { keyframes } from "styled-components";
import { Grid, rem } from "@boxt/design-system";

import { ReactComponent as LoadingSVG } from "@Images/icons/ellipse.svg";

export const StyledGridCol = styled(Grid.Col)`
  max-width: ${rem(528)};
  margin: 0 auto;
`;

const iconRotate = keyframes`
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(LoadingSVG)`
  animation: ${iconRotate} 1s linear infinite;
`;
