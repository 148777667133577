"use client";

import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.coral.normal};
`;

export const Price = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  min-width: ${rem(67)};
  align-items: center;
  z-index: 1;
  height: 100%;
  top: 0;
  border: unset;
  cursor: pointer;
  padding: ${rem(6)};
  background-color: ${colors.coral.normal};
  border-radius: 0 ${rem(6)} ${rem(6)} 0;

  ${breakpoint("md")} {
    min-width: ${rem(75)};
    padding: ${rem(6)} ${rem(12)};
  }

  ${breakpoint("lg")} {
    min-width: auto;
    border-radius: ${rem(6)} 0 0 ${rem(6)};
    height: auto;
    top: ${rem(8)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${includeSpacing({ mh: { sm: 1, lg: 2 } })}
  ${breakpoint("lg")} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  ${includeSpacing({ mb: 2, mh: 1 })}
  padding: ${rem(2)};
  ${breakpoint("lg")} {
    width: ${rem(270)};
    min-height: ${rem(340)};
  }
  ${breakpoint("xlg")} {
    width: ${rem(320)};
  }
`;
