import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, H4, OrderedList, Paragraph, UnorderedList } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { BOXT_CUSTOMER_URLS, REPAIR_URLS } from "@Collections/routes";
import Image from "@Components/Image";
import useChat from "@Hooks/useChat";

import { BulletPoint, ImageWrapper, InfoContainer } from "./styles";

export type Props = {
  i18n: {
    question: string;
    namespace: string;
  };
};

const HelpInfo = ({ i18n: { namespace, question } }: Props) => {
  const { openChat } = useChat();
  const { t } = useTranslation(namespace);

  const translateObject: Record<string, string> = t(`questions.${question}`, {}, { returnObjects: true });
  const images: Record<string, string>[] | null =
    (translateObject?.sideDrawerImages as unknown as Record<string, string>[]) || null;

  const hasImages = Array.isArray(images) && images.length > 0;
  return (
    <InfoContainer>
      <Trans
        i18nKey={`${namespace}:questions.${question}.sideDrawerText`}
        components={{
          title: <H4 bottom={6} align="left" boxtTheme="slate" />,
          p: <Paragraph bottom={4} align="left" boxtTheme="slate" as="div" />,
          s: <strong />,
          r: <Paragraph as="span" boxtTheme="coral" />,
          bp: <BulletPoint />,
          ol: <OrderedList boxtTheme="slate" />,
          ul: <UnorderedList boxtTheme="slate" />,
          li: <li />,
          span: <span />,
          number: <Button boxtTheme="coral" skin="link" href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`} />,
          livechat: <Button boxtTheme="coral" skin="link" onClick={openChat} />,
          landing: (
            <Button
              boxtTheme="coral"
              skin="link"
              href={REPAIR_URLS.LANDING}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
          fullList: (
            <Button
              boxtTheme="coral"
              skin="link"
              href={`${REPAIR_URLS.LANDING}#prices`}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
          questionnaire: (
            <Button
              boxtTheme="coral"
              skin="link"
              href={BOXT_CUSTOMER_URLS.BOILER}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
        }}
      />
      {hasImages && (
        <ImageWrapper>
          {images.map(({ image, alt }, index) => (
            <Image src={image} alt={alt} width={250} height={250} key={`${alt}-${index}`} />
          ))}
        </ImageWrapper>
      )}
    </InfoContainer>
  );
};

export default HelpInfo;
