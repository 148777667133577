import useTranslation from "next-translate/useTranslation";
import { Button } from "@boxt/design-system";

import { ButtonWrapper, Container, StartButton } from "./styles";

export type Props = {
  onFinishQuote?: () => void;
  onStartQuote: () => void;
  isPostcodeAtTheEndOfaScreener: boolean;
  hasExistingScreening: boolean;
};

const FinishQuote = ({ onFinishQuote, onStartQuote, hasExistingScreening, isPostcodeAtTheEndOfaScreener }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const hasGetPriceButton = isPostcodeAtTheEndOfaScreener && !hasExistingScreening;

  return (
    <Container $hasMultipleButtons={!hasGetPriceButton}>
      {hasExistingScreening && (
        <ButtonWrapper mr={{ sm: 0, md: 2 }} mb={{ sm: 2, md: 0 }}>
          <Button isFullWidth size="large" boxtTheme="jade" onClick={onFinishQuote}>
            {tCommon("finish-quote")}
          </Button>
        </ButtonWrapper>
      )}
      {hasGetPriceButton ? (
        <Button isFullWidth size="large" boxtTheme="jade" onClick={onStartQuote}>
          {tCommon("get-your-price")}
        </Button>
      ) : (
        <StartButton isFullWidth size="large" skin="plain" isThemeApplied={false} onClick={onStartQuote}>
          {tCommon("start-quote")}
        </StartButton>
      )}
    </Container>
  );
};

export default FinishQuote;
