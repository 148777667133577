import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import type { KeenSliderOptions } from "keen-slider/react";
import { breakpoints, H4, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";
import type { MapInstruction } from "@Components/Map/types";
import Slider from "@Components/pages/common/Slider";

import { Container, CustomDotIcon, ImageContainer, Slide, SlideContent, SliderWrapper, Wrapper } from "./styles";

const sliderConfig: KeenSliderOptions = {
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      slides: {
        perView: 1,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
      loop: false,
      initial: 0,
    },
    [`(min-width: ${breakpoints.md.width})`]: {
      slides: {
        perView: 2,
        origin: "center",
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(1, true, { duration: 0 }),
      loop: false,
      initial: 1,
    },
    [`(min-width: ${breakpoints.lg.width})`]: {
      slides: {
        perView: 3,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
      loop: false,
      initial: 0,
    },
  },
};

export type Props = {
  onConfirm: () => void;
};

const MobileView = ({ onConfirm }) => {
  const { t } = useTranslation("map");

  const { t: tCommon } = useTranslation("common");

  const [currentSlide, setCurrentSlide] = useState(0);

  const mapInstructions: MapInstruction[] = t("select-roof.cards", {}, { returnObjects: true });

  const handleSlideChange = (slideIndex: number) => {
    setCurrentSlide(slideIndex);
  };

  const handleActionButtonClick = () => {
    if (currentSlide === 2) {
      onConfirm();
    }
  };

  return (
    <Wrapper>
      <Container>
        <Slider
          alwaysShowDots
          actionButtonText={currentSlide === 2 ? tCommon("actions.get-started") : tCommon("actions.next")}
          includeSpacing={false}
          initiallyLoop={false}
          isOverflow
          sliderOptions={sliderConfig}
          wrapperStyle={SliderWrapper}
          DotIcon={CustomDotIcon}
          onSlideChange={handleSlideChange}
          onActionButtonClick={handleActionButtonClick}
        >
          {mapInstructions.map(({ mobileImage, subtitle, title }, index) => (
            <Slide
              className={`keen-slider__slide number-slide-${index}`}
              key={`select-your-roof-${index}`}
              data-testid="select-your-roof-mobile-slide"
            >
              <SlideContent>
                <Spacing mh={2} mt={2} mb={3}>
                  <Spacing mb={3}>
                    <ImageContainer>
                      <Image src={mobileImage} alt={title} layout="fill" objectFit="cover" />
                    </ImageContainer>
                  </Spacing>
                  <Spacing mb={1}>
                    <H4 align="center">{title}</H4>
                  </Spacing>
                  <Paragraph align="center" data-testid={`select-your-roof-mobile-slide-description-${index}`}>
                    {subtitle}
                  </Paragraph>
                </Spacing>
              </SlideContent>
            </Slide>
          ))}
        </Slider>
      </Container>
    </Wrapper>
  );
};

export default MobileView;
