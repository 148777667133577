import useTranslation from "next-translate/useTranslation";
import { useFormikContext } from "formik";
import { Button, Grid, H5, Spacing } from "@boxt/design-system";

import type { Question } from "@StateMachines/Screener";
import FormikRadio from "@Components/Forms/FormikRadio/FormikRadio";
import FormikTextInput from "@Components/Forms/FormikTextInput/FormikTextInput";
import { screener } from "@Locators";

import type { UnitOfElectricityValues } from "../../UnitOfElectricity";

import { ButtonContainer, RadioContainer, RadioInnerContainer } from "./styles";

type Props = {
  i18nNamespace: string;
  isAnswering: boolean;
  isValid: boolean;
  onSubmit: () => void;
  questionParentKey: Question;
};

const UnitOfElectricityForm = ({ i18nNamespace, isAnswering, isValid, onSubmit, questionParentKey }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { values } = useFormikContext<UnitOfElectricityValues>();

  return (
    <Grid.Row>
      <Grid.Col sm={4} md={{ span: 6, offset: 1 }} lg={{ span: 10, offset: 3 }}>
        <Spacing mb={4}>
          <RadioContainer>
            <H5 bottom={4}>
              {t(`questions.${questionParentKey}.unit_of_electricity.form.do-you-have-different-rate-title`)}
            </H5>
            <RadioInnerContainer>
              <Spacing mr={{ sm: 0, md: 3 }} mb={{ sm: 1, md: 0 }}>
                <FormikRadio
                  name="differentRateAtNight"
                  value="no"
                  i18nNamespace={i18nNamespace}
                  id="notDifferentRateAtNight"
                  testId={`${screener.unitOfElectricity}notDifferentRateAtNight`}
                />
              </Spacing>
              <FormikRadio
                name="differentRateAtNight"
                value="yes"
                i18nNamespace={i18nNamespace}
                id="differentRateAtNight"
                testId={`${screener.unitOfElectricity}differentRateAtNight`}
              />
            </RadioInnerContainer>
          </RadioContainer>
        </Spacing>
        <Spacing mb={4}>
          {values.differentRateAtNight === "no" ? (
            <FormikTextInput
              name="unitPrice"
              i18nNamespace={i18nNamespace}
              hasPlaceholder
              type="number"
              min={0}
              testId={`${screener.unitOfElectricity}unitPrice`}
            />
          ) : (
            <>
              <Spacing mb={3}>
                <FormikTextInput
                  name="dayUnitPrice"
                  i18nNamespace={i18nNamespace}
                  hasPlaceholder
                  type="number"
                  min={0}
                  testId={`${screener.unitOfElectricity}dayUnitPrice`}
                />
              </Spacing>
              <FormikTextInput
                name="nightUnitPrice"
                i18nNamespace={i18nNamespace}
                hasPlaceholder
                type="number"
                min={0}
                testId={`${screener.unitOfElectricity}nightUnitPrice`}
              />
            </>
          )}
        </Spacing>
        <ButtonContainer>
          <Button
            onClick={onSubmit}
            boxtTheme="jade"
            disabled={!isValid || isAnswering}
            testId={`${screener.unitOfElectricity}button`}
            isFullWidth
          >
            {t(`questions.${questionParentKey}.unit_of_electricity.form.button`)}
          </Button>
        </ButtonContainer>
      </Grid.Col>
    </Grid.Row>
  );
};

export default UnitOfElectricityForm;
