import NextTrans from "next-translate/Trans";
import type { FontTheme, PaddingStep, Weight } from "@boxt/design-system";
import { Button, H5, OrderedList, Paragraph, UnorderedList } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { BulletPoint } from "@Components/pages/common/HelpInfo/styles";
import useChat from "@Hooks/useChat";

import ButtonLink from "./ButtonLink";
import { StyledLi } from "./styles";

export type StyleConfig = {
  [key: string]: {
    [key: string]: string | number;
  };
};

export type Props = {
  i18nNamespace: string;
  styleConfig?: StyleConfig;
};

const Trans = ({ i18nNamespace, styleConfig }: Props) => {
  const { openChat } = useChat();

  return (
    <NextTrans
      i18nKey={i18nNamespace}
      components={{
        subtitle: <H5 bottom={4} />,
        // @ts-ignore
        a: <ButtonLink />,
        p: (
          <Paragraph
            bottom={(styleConfig?.paragraph?.bottom as PaddingStep) || 4}
            align="left"
            boxtTheme={(styleConfig?.paragraph?.theme as FontTheme) || "coalLighter"}
            as="div"
            weight={(styleConfig?.paragraph?.weight as Weight) || "regular"}
          />
        ),
        s: <strong />,
        r: <Paragraph as="span" boxtTheme="coral" />,
        bp: <BulletPoint $boxtTheme={(styleConfig?.bp?.theme as FontTheme) || "coral"} />,
        boxtcustomerservicenumber: (
          <Button boxtTheme="coral" skin="link" href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`}>
            {CONTACT_DETAILS.PHONE}
          </Button>
        ),
        ol: <OrderedList boxtTheme="coalLighter" />,
        ul: <UnorderedList boxtTheme="coalLighter" />,
        li: (
          <StyledLi
            $boxtTheme={(styleConfig?.li?.theme as FontTheme) || "coalLighter"}
            $weight={(styleConfig?.li?.weight as Weight) || "regular"}
          />
        ),
        span: <span />,
        br: <br />,
        livechat: <Button boxtTheme="coral" skin="link" onClick={openChat} />,
      }}
    />
  );
};

export default Trans;
