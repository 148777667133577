import type { ReactElement } from "react";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, Paragraph, Spacing } from "@boxt/design-system";

import type { Answer, Question } from "@StateMachines/Screener";
import { screener } from "@Locators";

import { LoadingIcon, StyledGridCol } from "./styles";

export type Props = {
  answer: Answer;
  children: ReactElement;
  hasAdditionalInfo?: boolean;
  i18nNamespace: string;
  isAnswering: boolean;
  isLoading?: boolean;
  onAnswer: (question: Question, answer: Answer) => void;
  question: Question;
};

const Info = ({
  answer,
  children,
  hasAdditionalInfo = false,
  i18nNamespace,
  isAnswering,
  isLoading,
  onAnswer,
  question,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");

  return (
    <Grid.Row>
      <StyledGridCol>
        {children}
        {hasAdditionalInfo && (
          <Spacing mt={4} mh={{ sm: 3, md: 0 }}>
            <Paragraph boxtTheme="slate" align="center">
              {t("additional-info")}
            </Paragraph>
          </Spacing>
        )}
        <Spacing mb={6} mt={4}>
          <Button
            data-testid={`${screener.button}-${answer}`}
            isFullWidth
            onClick={() => onAnswer(question, answer)}
            boxtTheme="jade"
            disabled={isLoading || isAnswering}
            loadingConfig={{
              isLoading: Boolean(isLoading),
              icon: {
                svg: <LoadingIcon />,
                size: "small",
                afix: "left",
              },
              copy: tCommon("loading"),
            }}
          >
            {t("btn-text")}
          </Button>
        </Spacing>
      </StyledGridCol>
    </Grid.Row>
  );
};
export default Info;
