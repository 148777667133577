import styled, { css } from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import { ReactComponent as Dot } from "@Images/icons/dot.svg";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
  ${breakpoint("md")} {
    display: none;
  }
`;

export const Container = styled.div`
  overflow: hidden;
  padding: ${rem(16)};

  ${breakpoint("md")} {
    display: none;
  }
`;

export const SliderWrapper = css`
  overflow: visible;
  position: relative;
  width: ${rem(400)};

  ${includeSpacing({ mv: { sm: 4, md: 3 } })};
`;

export const CustomDotIcon = styled(Dot)<{ $selected: boolean }>`
  color: ${({ $selected }) => ($selected ? colors.coral.normal : colors.white)};
  cursor: pointer;
  margin: 0 ${rem(5)};
`;

export const Slide = styled.div`
  background: ${colors.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const SlideContent = styled.div`
  min-height: ${rem(310)};
`;

export const ImageContainer = styled.div`
  border-radius: 16px;
  height: ${rem(170)};
  overflow: hidden;
  position: relative;
`;
