import * as Yup from "yup";

import { Question } from "@StateMachines/Screener";

const schemas = (t, counterConfig) => {
  const defaultSchema = Yup.string().max(50, t("form.errors.too-long"));

  return {
    [Question.WhereEvInstalled]: Yup.object().shape({
      [Question.WhereEvInstalled]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 500, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.NewAirConLocation]: Yup.object().shape({
      [Question.NewAirConLocation]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 150, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.FaultCode]: Yup.object().shape({ [Question.FaultCode]: defaultSchema }),
    [Question.BrokenPlace]: Yup.object().shape({
      [Question.BrokenPlace]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 500, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.BoilerLocation]: Yup.object().shape({
      [Question.BoilerLocation]: defaultSchema.required(t("form.errors.required")),
    }),
    [Question.GoogleRef]: Yup.object().shape({
      [Question.GoogleRef]: defaultSchema.required(t("form.errors.required")).matches(/^[0-9]{1}-[0-9]{13}$/),
    }),
    [Question.BoilerGeneralProblemDescription]: Yup.object().shape({
      [Question.BoilerGeneralProblemDescription]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 500, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.EvGeneralProblemDescription]: Yup.object().shape({
      [Question.EvGeneralProblemDescription]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 500, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.AcGeneralProblemDescription]: Yup.object().shape({
      [Question.AcGeneralProblemDescription]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 10, t("form.errors.too-short", { minCount: counterConfig?.min ?? 10 }))
        .max(counterConfig?.max ?? 500, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 500 })),
    }),
    [Question.CurrentBoilerLocation]: Yup.object().shape({
      [Question.CurrentBoilerLocation]: Yup.string()
        .required(t("form.errors.required"))
        .min(counterConfig?.min ?? 1, t("form.errors.too-short", { minCount: counterConfig?.min ?? 1 }))
        .max(counterConfig?.max ?? 150, t("form.errors.too-long", { maxCount: counterConfig?.max ?? 150 })),
    }),
    [Question.AnnualElectricityUsage]: Yup.object().shape({
      [Question.AnnualElectricityUsage]: Yup.number()
        .required(t("form.errors.required"))
        .moreThan(-1, t("form.errors.positive"))
        .integer(t("form.errors.whole-number")),
    }),
    [Question.NumOfPanels]: Yup.object().shape({
      [Question.NumOfPanels]: Yup.number()
        .moreThan(-1, t("form.errors.positive"))
        .min(1, t("form.errors.more-than", { minCount: 0 }))
        .max(50, t("form.errors.less-than", { maxCount: 51 }))
        .integer(t("form.errors.whole-number"))
        .required(t("form.errors.required")),
    }),
    [Question.EpcReference]: Yup.object().shape({
      [Question.EpcReference]: Yup.string().required(t("form.errors.required")),
    }),
    [Question.FloorArea]: Yup.object().shape({
      [Question.FloorArea]: Yup.number()
        .required(t("form.errors.floor-area-required"))
        .min(15, t("form.errors.more-than", { minCount: 15 }))
        .max(500, t("form.errors.less-than", { maxCount: 500 }))
        .integer(t("form.errors.whole-number")),
    }),
    [Question.HeatingUsage]: Yup.object().shape({
      [Question.HeatingUsage]: Yup.number()
        .required(t("form.errors.heating-usage-required"))
        .min(100, t("form.errors.more-than", { minCount: 100 }))
        .max(99000, t("form.errors.less-than", { maxCount: 99000 }))
        .integer(t("form.errors.whole-number")),
    }),
    [Question.HotWaterUsage]: Yup.object().shape({
      [Question.HotWaterUsage]: Yup.number()
        .required(t("form.errors.heating-usage-required"))
        .min(100, t("form.errors.more-than", { minCount: 100 }))
        .max(99000, t("form.errors.less-than", { maxCount: 99000 }))
        .integer(t("form.errors.whole-number")),
    }),
    [Question.NumOfRadiators]: Yup.object().shape({
      [Question.NumOfRadiators]: Yup.number()
        .required(t("form.errors.radiators-required"))
        .min(0, t("form.errors.more-than", { minCount: 0 }))
        .max(30, t("form.errors.less-than", { maxCount: 30 }))
        .integer(t("form.errors.whole-number")),
    }),
  };
};

export const getValidationSchema = (question, t, counterConfig) => schemas(t, counterConfig)[question];
