import Trans from "next-translate/Trans";
import { camelCase } from "lodash-es";

import Image from "@Components/Image";
import { yourBoiler } from "@Locators";

import { Container, Name, Red } from "./styles";

type Props = Readonly<{
  alt: string;
  isAnswering: boolean;
  logo: string;
  value: string;
  i18nKey?: string;
  onClick: () => void;
  skin: "primary" | "secondary";
}>;
const Item = ({ skin, value, logo, onClick, alt, isAnswering, i18nKey }: Props) => (
  <Container
    data-testid={`${yourBoiler.make.item}${camelCase(value)}`}
    skin={skin}
    onClick={onClick}
    disabled={isAnswering}
    aria-disabled={isAnswering}
  >
    {Boolean(logo) && <Image src={logo} height={60} width={60} alt={alt} />}
    <Name as="span" size="large" boxtTheme="slate" weight="bold">
      <Trans
        i18nKey={i18nKey as string}
        components={{
          r: <Red as="span" />,
        }}
      />
    </Name>
  </Container>
);

export default Item;
