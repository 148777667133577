"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeShadows, includeSpacing, rem } from "@boxt/design-system";

export const FloatingContainer = styled.div<{ $isContentCentered?: boolean }>`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  ${breakpoint("lg")} {
    margin: 0;
  }

  ${({ $isContentCentered }) =>
    $isContentCentered &&
    css`
      display: flex;
      justify-content: center;
    `}
  background: ${colors.white};

  &:first-child {
    border-radius: ${rem(12)} ${rem(12)} 0rem 0rem; // top corners
  }
  &:last-child {
    ${breakpoint("lg")} {
      border-radius: 0 0 ${rem(12)} ${rem(12)}; // bottom corners
      ${includeSpacing({ mb: 5 })}
    }
  }

  &:only-child {
    border-radius: ${rem(12)};
  }
`;

export const Shadow = styled.div`
  ${includeShadows(3)};
  border-radius: ${rem(24)};
  flex: 1;
`;

export const StyledHr = styled.hr`
  border: none;
  border-top: 1px dashed ${colors.platinum.darker};
`;
